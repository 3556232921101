var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"100px"}},[_c('a-form',{attrs:{"form":_vm.form,"layout":_vm.formLayout}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"新用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          '新用户名',
          {
            rules: [{ required: true, min: 6, message: '不能小于5位' }],
          } ]),expression:"[\n          '新用户名',\n          {\n            rules: [{ required: true, min: 6, message: '不能小于5位' }],\n          },\n        ]"}],attrs:{"placeholder":"请输入您要新建的用户名"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"新用户密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          '新用户密码',
          {
            rules: [{ required: true, min: 6, message: '不能小于5位' }],
          } ]),expression:"[\n          '新用户密码',\n          {\n            rules: [{ required: true, min: 6, message: '不能小于5位' }],\n          },\n        ]"}],attrs:{"placeholder":"请输入新用户密码"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"新用户的权限"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          '新用户的权限',
          {
            rules: [{ required: true }],
          } ]),expression:"[\n          '新用户的权限',\n          {\n            rules: [{ required: true }],\n          },\n        ]"}],attrs:{"placeholder":"请输入新用户的权限"}})],1),_c('a-form-item',{attrs:{"wrapper-col":_vm.buttonItemLayout.wrapperCol}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }