var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"100px"}},[_c('a-form',{attrs:{"form":_vm.form,"layout":_vm.formLayout}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"原密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          '原密码',
          {
            rules: [{ required: true, min: 6, message: '不能小于5位' }],
          } ]),expression:"[\n          '原密码',\n          {\n            rules: [{ required: true, min: 6, message: '不能小于5位' }],\n          },\n        ]"}],attrs:{"placeholder":"请输入目前的密码"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          '新密码',
          {
            rules: [{ required: true, min: 6, message: '不能小于5位' }],
          } ]),expression:"[\n          '新密码',\n          {\n            rules: [{ required: true, min: 6, message: '不能小于5位' }],\n          },\n        ]"}],attrs:{"placeholder":"请输入新的密码"}})],1),_c('a-form-item',{attrs:{"wrapper-col":_vm.buttonItemLayout.wrapperCol}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }